import { ART_STORE, ALBUMS } from '../../constants';
import Fetcher from './fetcher';
import WorkerLog from '../../workerLog';

import {
  ViewerScriptContext,
  ArtStoreViewerScriptManager,
  CommonViewerScript,
  WixCodeApi,
  Utils,
  BiLogger,
} from '@wix/common-pro-gallery-worker-wrapper';
import CartFedopsLogger from '../../components/cart/cartFedops';
import ThankyouFedops from '../../components/thankyou/thankyouFedops';

const context = new ViewerScriptContext(ART_STORE.SENTRY_DSN);
const storeManager = new ArtStoreViewerScriptManager(context);

const createAppController = (controllerConfig) => {
  const { widgets, type, wixCodeApi, compId } = controllerConfig;
  const WIDGET_ARRAY = [
    ART_STORE.GALLERY_WIDGET_ID,
    ART_STORE.CART_WIDGET_ID,
    ART_STORE.THANK_YOU_WIDGET_ID,
  ];
  if (!WIDGET_ARRAY.includes(type)) {
    return { pageReady: () => {} };
  }

  const isSSR = wixCodeApi.window.rendering.env === 'backend';
  storeManager.initCartApiIfNeeded(isSSR);
  storeManager.initTranslationsIfNeeded();
  const storeApi = storeManager.getStoreApi(wixCodeApi, compId);
  const consentPolicy = storeManager.getConsentPolicy(wixCodeApi);
  storeManager.addStoreWidgetsIfNeeded(widgets);
  const galleryWixCodeApi = new WixCodeApi();
  switch (type) {
    case ART_STORE.GALLERY_WIDGET_ID:
      return getGalleryWidgetController({
        controllerConfig,
        consentPolicy,
        storeApi,
        galleryWixCodeApi,
      });
    case ART_STORE.CART_WIDGET_ID:
      return getCartWidgetController({
        controllerConfig,
        consentPolicy,
        storeApi,
        isSSR,
        galleryWixCodeApi,
      });
    case ART_STORE.THANK_YOU_WIDGET_ID:
      return getThankYouWidgetController({
        controllerConfig,
        consentPolicy,
        storeApi,
        galleryWixCodeApi,
      });
    default:
      return { pageReady: () => {} };
  }
};

function getGalleryWidgetController({
  controllerConfig,
  consentPolicy,
  storeApi,
  galleryWixCodeApi,
}) {
  const {
    appParams: { instance },
    config: {
      style: { styleParams },
    },
    setProps,
    compId,
    type,
    wixCodeApi,
  } = controllerConfig;
  const biLogger = new BiLogger(
    context.getContext().platformServices.biLoggerFactory(),
    Utils.parseViewMode(controllerConfig.wixCodeApi.window.viewMode),
    false, // isArtStore
  );

  const reportBiLog = (funcName, data) => {
    biLogger.log(fetcher.galleryId, funcName, data);
  };
  const SSRWorkerLog = new WorkerLog();
  const fetcher = new Fetcher({
    context,
    controllerConfig,
    reportBiLog,
    SSRWorkerLog,
  });
  const commonViewerScript = new CommonViewerScript({
    context,
    controllerConfig,
    APP_DEFINITION_ID: ART_STORE.ARTSTORE_APP_DEFINITION_ID,
    GALLERY_WIDGET_ID: ART_STORE.GALLERY_WIDGET_ID,
    isArtStore: true,
    fetcher,
    SSRWorkerLog,
  });

  const tryToReportAppLoaded = commonViewerScript.getTryToReportAppLoaded();
  return {
    pageReady: () => {
      try {
        const renderGallery = (viewPortState) => {
          let notInView = false;
          if (viewPortState && !viewPortState.in) {
            // notInView can be true only in SSR.
            // tryToReportAppLoaded for this case will be called later here in "if (commonViewerScript.isSSR())"
            notInView = true;
          }
          commonViewerScript.loadInitialBlueprint();
          commonViewerScript.loadDirectFullscreen();
          const initPromise = new Promise((resolve, reject) => {
            try {
              storeManager
                .getConnectedProviders(
                  instance,
                  styleParams, // TODO: remove
                  ART_STORE.ARTSTORE_APP_DEFINITION_ID,
                )
                .then((connectedProviders) => {
                  setProps({ appInstance: instance });
                  if (connectedProviders) {
                    // this is art-store
                    Promise.all([
                      commonViewerScript.getBlueprintReadyPromise(),
                      commonViewerScript.getDirectFullscreenReadyPromise(),
                    ]).then(() => {
                      resolve({
                        connectedProviders: connectedProviders,
                      });
                    });
                  } else {
                    // albums art-store
                    const skipItems = storeManager.shouldSkipItems(wixCodeApi);
                    if (skipItems) {
                      resolve({});
                    } else {
                      Promise.all([
                        commonViewerScript.getWixCodeBlueprintReadyPromise(), // for albums we must have wix-code so we wait for them with a promise
                      ]).then(() => {
                        resolve({
                          blueprint: commonViewerScript.getBlueprint(),
                        });
                      });
                    }
                  }
                });
            } catch (e) {
              console.error(e);
              reject(e);
            }
          });
          return initPromise
            .catch((e) => {
              commonViewerScript.sentryReport(e);
              console.error('Waiting for promises failed', e);
            })
            .then(({ connectedProviders, blueprint }) => {
              const cssBaseUrls = commonViewerScript.getbaseUrls();
              let initalProps = {
                ...commonViewerScript.getCommonGalleryProps(true),
                reportBiLog,
                storeApi,
                onItemClicked: commonViewerScript.getOnItemClickedFunc(
                  galleryWixCodeApi,
                ),
                watermarkData: storeManager.isAlbums(styleParams)
                  ? undefined
                  : commonViewerScript.getWatermark(),
                onLinkNavigation: commonViewerScript.getOnLinkNavigationFunc(),
                setMetaTags: commonViewerScript.getSetMetaTagsFunc(),
                cssBaseUrl:
                  cssBaseUrls.santaWrapperBaseUrl || cssBaseUrls.staticsBaseUrl,
                styleParams: controllerConfig.config.style.styleParams,
                notInView,
                totalItemsCount: commonViewerScript.getTotalItemsCount(),
                ...blueprint,
                directFullscreenItem: commonViewerScript.getDirectFullscreenItem(),
                directFullscreenMockBlueprint: commonViewerScript.getDirectFullscreenMockBlueprint(),
              };
              if (connectedProviders) {
                initalProps = {
                  ...initalProps,
                  ...storeManager.getInitalProps(
                    { compId, setProps, type, consentPolicy },
                    connectedProviders,
                    ART_STORE.ARTSTORE_APP_DEFINITION_ID,
                  ),
                };
              }
              setProps(initalProps);
              if (commonViewerScript.isSSR()) {
                tryToReportAppLoaded(true);
              }
              return commonViewerScript.getWarmupData();
            });
        };

        return commonViewerScript
          .getViewPortPromise()
          .then(renderGallery)
          .catch((error) => {
            console.error(error);
            commonViewerScript.sentryReport({
              errorMessage: 'viewportPromise rejected!',
              error,
            });
            tryToReportAppLoaded();
          });
      } catch (e) {
        console.error(e);
        commonViewerScript.sentryReport(e);
      }
    },
    exports: () => {
      try {
        return galleryWixCodeApi.generateApi({
          proGalleryStore: commonViewerScript.getPgStore(),
          setNewStyleParams: (sp) =>
            commonViewerScript.handleNewStyleParams(sp),
          setClickedIdx: (clickedIdx) =>
            commonViewerScript.setClickedIdx(clickedIdx),
          setNewSettings: (settings) => {
            const { watermark, galleryId } = settings;
            const store = commonViewerScript.getPgStore();
            watermark && setProps({ watermarkWixCode: watermark });
            galleryId && (fetcher.galleryId = galleryId);
            fetcher.setSettings(settings);
            store.recalculateStylesExpensively();
            if (!commonViewerScript.isSSR()) {
              storeManager
                .initStoreWixCodeApi(
                  settings,
                  styleParams,
                  ALBUMS.ALBUMS_APP_DEF_ID,
                )
                .then(({ connectedProviders, additionalProviderParams }) => {
                  additionalProviderParams &&
                    (store.freeArtStore =
                      additionalProviderParams.freeArtStore);
                  setProps(
                    storeManager.getInitalProps(
                      { compId, setProps, type, consentPolicy },
                      connectedProviders,
                      ALBUMS.ALBUMS_APP_DEF_ID,
                      additionalProviderParams,
                    ),
                  );
                });
            }
          },
          setManualDimensions: commonViewerScript.getSetManualDimensionsFunc(),
        });
      } catch (e) {
        console.error('Could not create wix code api: ', e);
        commonViewerScript.sentryReport(e);
      }
    },
  };
}

function getCartWidgetController({
  controllerConfig,
  consentPolicy,
  storeApi,
  isSSR,
  galleryWixCodeApi,
}) {
  const {
    appParams: { instance },
    config: {
      style: { styleParams },
      publicData,
    },
    setProps,
    compId,
    type,
  } = controllerConfig;
  if (!isSSR && !storeManager.isAlbums(styleParams)) {
    storeManager.initTranslations(setProps);
  }
  const fedopsLogger = new CartFedopsLogger(context);
  fedopsLogger.reportAppLoadStarted();
  return {
    pageReady: () => {
      try {
        storeManager
          .getConnectedProviders(
            instance,
            styleParams, // TODO: remove
            ART_STORE.ARTSTORE_APP_DEFINITION_ID,
          )
          .then((connectedProviders) => {
            let initalProps = {
              styleParams,
              publicData,
              storeApi,
              consentPolicy,
              setActiveStoreWidget: () =>
                storeManager.changeActiveStoreWidget({
                  compId,
                  setProps,
                  type,
                }),
              tryToReportAppLoaded: () => fedopsLogger.tryReportAppLoaded(),
              appInstance: instance,
            };
            if (connectedProviders) {
              initalProps = {
                ...initalProps,
                ...storeManager.getInitalProps(
                  { compId, setProps, type, consentPolicy },
                  connectedProviders,
                  ART_STORE.ARTSTORE_APP_DEFINITION_ID,
                ),
              };
            }
            setProps(initalProps);
          });
      } catch (e) {
        console.error(e);
      }
    },
    exports: () => {
      try {
        return galleryWixCodeApi.generateCartApi(({ settings }) => {
          storeManager
            .initStoreWixCodeApi(
              settings,
              styleParams,
              ALBUMS.ALBUMS_APP_DEF_ID,
            )
            .then(({ connectedProviders, additionalProviderParams }) => {
              setProps(
                storeManager.getInitalProps(
                  { compId, setProps, type, consentPolicy },
                  connectedProviders,
                  ALBUMS.ALBUMS_APP_DEF_ID,
                  additionalProviderParams,
                ),
              );
            });
        });
      } catch (e) {
        console.error('Could not create an api for the cart widget ', e);
      }
    },
  };
}

function getThankYouWidgetController({
  controllerConfig,
  consentPolicy,
  storeApi,
  galleryWixCodeApi,
}) {
  const {
    appParams: { instance },
    config: {
      style: { styleParams },
    },
    setProps,
    compId,
    type,
    wixCodeApi,
  } = controllerConfig;
  const thankyouPageLogger = new ThankyouFedops(context);
  thankyouPageLogger.reportAppLoadStarted();
  const queryParams = wixCodeApi.location.query;
  return {
    pageReady: () => {
      try {
        storeManager
          .getConnectedProvidersForThankYouPage(
            instance,
            styleParams, // TODO: remove
            ART_STORE.ARTSTORE_APP_DEFINITION_ID,
            wixCodeApi,
          )
          .then((connectedProviders) => {
            let initalProps = {
              styleParams,
              storeApi,
              queryParams,
              consentPolicy,
              tryToReportAppLoaded: () =>
                thankyouPageLogger.tryReportAppLoaded(),
            };
            if (connectedProviders) {
              initalProps = {
                ...initalProps,
                ...storeManager.getInitalProps(
                  { compId, setProps, type, consentPolicy },
                  connectedProviders,
                  ART_STORE.ARTSTORE_APP_DEFINITION_ID,
                ),
              };
            }
            setProps(initalProps);
          });
      } catch (e) {
        console.error(e);
      }
    },
    exports: () => {
      try {
        return galleryWixCodeApi.generateCartApi(({ settings }) => {
          storeManager
            .initStoreWixCodeApi(
              settings,
              styleParams,
              ALBUMS.ALBUMS_APP_DEF_ID,
            )
            .then(({ connectedProviders, additionalProviderParams }) => {
              setProps(
                storeManager.getInitalProps(
                  { compId, setProps, type, consentPolicy },
                  connectedProviders,
                  ALBUMS.ALBUMS_APP_DEF_ID,
                  additionalProviderParams,
                ),
              );
            });
        });
      } catch (e) {
        console.error('Could not create an api for the thank you page ', e);
      }
    },
  };
}
export const wrappedFunctions = {
  initAppForPage: CommonViewerScript.getInitAppForPageFunc(context),
  createControllers: CommonViewerScript.getCreateControllers(
    createAppController,
    context.getSentry(),
  ),
};
